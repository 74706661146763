@charset "UTF-8";
/*  Reset CSS File Sass */
html {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box; }

* {
  box-sizing: inherit; }
  *:before, *:after {
    box-sizing: inherit; }

body {
  -webkit-font-smoothing: antialiased;
  color: #222;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  position: relative;
  text-rendering: optimizeLegibility; }

h1, h2, h3, h4, h5, h6, dl, dd, ol, ul, menu, figure, blockquote, p, pre, form {
  margin: 0; }

menu, ol, ul {
  list-style-image: none;
  list-style: none;
  padding: 0; }

main {
  display: block; }

hr {
  box-sizing: content-box;
  height: 1px;
  overflow: visible; }

a {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  color: inherit;
  text-decoration: underline;
  text-underline-position: under; }

audio, canvas, iframe, img, svg, video {
  vertical-align: middle; }

svg {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden; }

input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

/*  Scss Modular Fluid Text Document  */
html {
  color: #222;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 100%;
  line-height: 1.5;
  scroll-behavior: smooth; }

body {
  font-size: 1rem;
  overflow-wrap: normal; }

h1,
h2,
h3,
h4 {
  letter-spacing: 0.0313em;
  margin: 0.75em 0;
  font-family: nunito;
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
  text-underline-position: under; }

h1:focus,
h2:focus,
h3:focus,
h4:focus,
button:focus {
  outline: 2px solid red; }

p,
figcaption {
  letter-spacing: 0.0313em;
  margin: 0.75em 0; }

h1 {
  font-size: 1.731rem; }
  @media (min-width: 30em) {
    h1 {
      font-size: calc(1.731rem + 1.362 * (100vw - 30em) / 50); } }
  @media (min-width: 80em) {
    h1 {
      font-size: 3.093rem; } }

h2 {
  font-size: 1.5rem; }
  @media (min-width: 30em) {
    h2 {
      font-size: calc(1.5rem + 0.968 * (100vw - 30em) / 50); } }
  @media (min-width: 80em) {
    h2 {
      font-size: 2.468rem; } }

h3 {
  font-size: 1.25rem; }
  @media (min-width: 30em) {
    h3 {
      font-size: calc(1.25rem + 0.718 * (100vw - 30em) / 50); } }
  @media (min-width: 80em) {
    h3 {
      font-size: 1.968rem; } }

h4 {
  font-size: 1.175rem; }
  @media (min-width: 30em) {
    h4 {
      font-size: calc(1.175rem + 0.543 * (100vw - 30em) / 50); } }
  @media (min-width: 80em) {
    h4 {
      font-size: 1.718rem; } }

a,
p,
li,
figcaption,
button {
  font-size: 1.031rem; }
  @media (min-width: 30em) {
    a,
    p,
    li,
    figcaption,
    button {
      font-size: calc(1.031rem + 0.219 * (100vw - 30em) / 50); } }
  @media (min-width: 80em) {
    a,
    p,
    li,
    figcaption,
    button {
      font-size: 1.25rem; } }

.small {
  font-size: small; }

a {
  color: #1300e1;
  letter-spacing: 0.0313em;
  padding: 4px;
  text-decoration: underline;
  text-underline-position: under;
  white-space: normal; }
  a:visited {
    color: #0070f1; }
  a:focus {
    background-color: rgba(0, 255, 255, 0.3);
    border-bottom: 1px solid red;
    color: #001090;
    outline-style: auto;
    outline: 2px solid red;
    text-decoration: none; }
  a:hover {
    background-color: rgba(0, 255, 255, 0.3);
    border-bottom: 1px solid red;
    color: #001090;
    cursor: pointer;
    text-decoration: none; }
  a:before, a:after {
    text-decoration: none; }

a[target="_blank"]:not([href*="tauchclub-octopus.ch"]):not([href*="pdf/"]) {
  background-image: url("../img/extern.svg");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 14px;
  padding: 2px 16px 0 0;
  text-decoration: none; }

a[target="_blank"]:not([href*="tauchclub-octopus.ch"]):not([href^="pdf/"]):hover {
  background-color: #ffdcdc; }

a[href*="mailto:"] {
  background-image: url("../img/mail.svg");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 25px; }

li {
  list-style-type: disc;
  margin-left: 1.5rem; }

.icon {
  align-self: center;
  fill: currentColor;
  height: 1em;
  margin-right: 3px;
  position: relative;
  stroke-width: 0;
  stroke: currentColor;
  width: 1em; }

.icon-file-pdf {
  color: red;
  margin-left: 2px; }

/* nunito-regular - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: local("Nunito Regular"), local("Nunito-Regular"), url("../fonts/nunito-v12-latin-regular.woff2") format("woff2"), url("../fonts/nunito-v12-latin-regular.woff") format("woff");
  font-display: swap; }

/*    End Fluid scss    */
hr {
  border: 0;
  border-top: 1px solid #00e5ff;
  display: block;
  margin: 2em auto;
  padding: 0;
  width: 90%; }

img {
  height: auto;
  max-width: 100%; }

.hidden, [hidden] {
  display: none !important; }

.vh {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px; }
  .vh.focusable:active, .vh.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    white-space: inherit;
    width: auto; }

.clearfix:after, .clearfix:before {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.nobreak {
  hyphens: none;
  white-space: nowrap; }

.box {
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
  border-color: #007ffc;
  border-radius: .3em;
  border-style: solid;
  border-width: 1px;
  color: #111;
  margin: 1.5em 0;
  padding: 1.4vw; }
  .box h3:first-of-type,
  .box h4:first-of-type {
    margin-top: 0.2em; }

.info {
  background-color: #d0ffff;
  min-width: 200px;
  border: 1px solid blue;
  margin-left: 3px; }

.adress {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap; }

.success {
  background: #dcffcf; }

.float-left {
  display: block;
  float: left;
  margin: 0 1em 1.25em 0; }

.float-right {
  display: block;
  float: right;
  margin: 0 0 1.25em 1em; }

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0.66em 0;
  text-align: center; }

.text-links {
  text-align: left; }

.red {
  color: red; }

.bold {
  font-weight: 700; }

.underline {
  text-decoration: underline;
  text-underline-position: under; }

.linklist {
  margin: 0 0 16px 18px; }
  .linklist:before {
    content: " - ";
    margin-left: -13px; }

/* Scss Document Mixins */
/* mixin usage header */
.gradient {
  background: -moz-linear-gradient(180deg, #00a9ff 0%, #00f4ff 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #00a9ff), color-stop(100%, #00f4ff));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(180deg, #00a9ff 0%, #00f4ff 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(180deg, #00a9ff 0%, #00f4ff 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(180deg, #00a9ff 0%, #00f4ff 100%);
  /* ie10+ */
  background: linear-gradient(180deg, #00a9ff 0%, #00f4ff 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two',GradientType=1 );
  /* ie6-9 */ }

/* mixin usage nav  */
.gradient-nav {
  background: -moz-linear-gradient(180deg, #00effb 0%, #36e9ff 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #00effb), color-stop(100%, #36e9ff));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(180deg, #00effb 0%, #36e9ff 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(180deg, #00effb 0%, #36e9ff 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(180deg, #00effb 0%, #36e9ff 100%);
  /* ie10+ */
  background: linear-gradient(180deg, #00effb 0%, #36e9ff 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two',GradientType=1 );
  /* ie6-9 */ }

.gradient-footer {
  background: -moz-linear-gradient(180deg, #00f3ff 0%, #36e9ff 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #00f3ff), color-stop(100%, #36e9ff));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(180deg, #00f3ff 0%, #36e9ff 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(180deg, #00f3ff 0%, #36e9ff 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(180deg, #00f3ff 0%, #36e9ff 100%);
  /* ie10+ */
  background: linear-gradient(180deg, #00f3ff 0%, #36e9ff 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two',GradientType=1 );
  /* ie6-9 */ }

/* Navigation   */
.no-js nav a#nav-m, .no-js nav a.close {
  background: transparent;
  border-radius: .3em;
  border: 2px solid #333;
  color: #000;
  display: block;
  font-size: 1.5rem;
  padding: .1em .25em;
  position: absolute;
  right: 2px;
  text-decoration: none;
  top: 3.875em;
  z-index: 2; }

.no-js nav a#nav-m:hover, .no-js nav a.close:hover {
  background-color: #f6eb00; }

.no-js nav a#nav-m img, .no-js nav a.close img {
  margin-left: 0.250em;
  width: 22px; }

.no-js nav a.close, .no-js nav a#nav-m:target {
  display: none; }

.no-js nav a:target ~ ul {
  display: flex;
  background-color: #00f5ff; }

.no-js nav a:target ~ .close {
  display: block; }

nav {
  background-color: transparent;
  color: #000; }
  nav a#nav-m, nav a.close {
    display: none; }

.no-js button.menu-toggle {
  display: none; }

button.menu-toggle {
  align-items: center;
  background-color: transparent;
  border-radius: 0.3em;
  border: 2px solid #333;
  color: #000;
  display: flex;
  font-size: 1.5rem;
  margin-left: auto;
  padding: 0.3em;
  position: absolute;
  right: 2px;
  top: 3.875em; }
  button.menu-toggle:hover {
    background-color: #f6eb00; }
  button.menu-toggle img {
    margin-left: 0.250em;
    width: 22px; }
  button.menu-toggle[aria-expanded="true"] img.menu-img, button.menu-toggle[aria-expanded="false"] img.close {
    display: none; }
  button.menu-toggle[aria-expanded="true"] img.close {
    display: inline-block; }

nav > ul {
  display: none;
  flex-direction: column;
  margin: 0 auto; }
  nav > ul.show {
    display: flex;
    background-color: #00f5ff; }
  nav > ul ul.dropdown {
    background-color: #00f5ff;
    flex-direction: column;
    max-height: 0;
    transition: .1s ease-in .8s;
    visibility: hidden;
    margin-top: 0; }
  nav > ul li.dropdown:hover ul.dropdown, nav > ul li.dropdown a:focus + ul.dropdown, nav > ul li.dropdown ul.dropdown:focus-within {
    max-height: 400px;
    transition: .3s ease-out .3s;
    visibility: visible; }

nav ul.dropdown.show {
  display: flex; }

nav ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative; }
  nav ul li.dropdown > a::after {
    content: " ▼";
    display: inline-block; }
  nav ul li a.menu-link {
    align-items: baseline;
    border-bottom: 1px solid gray;
    color: #000;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: .4rem 1.4rem;
    text-decoration: underline;
    text-underline-position: under; }
    nav ul li a.menu-link:hover, nav ul li a.menu-link:focus {
      background-color: #0a2d6d;
      color: #f5faff;
      text-decoration-color: currentColor; }
    nav ul li a.menu-link.active {
      background-color: #d3d3d3;
      color: currentColor;
      cursor: default;
      text-decoration: none; }
      nav ul li a.menu-link.active:hover, nav ul li a.menu-link.active:focus {
        background-color: #d3d3d3;
        color: currentColor;
        cursor: default;
        text-decoration: none; }
    nav ul li a.menu-link svg {
      align-self: center; }
    nav ul li a.menu-link svg.icon-file-pdf {
      align-self: flex-end;
      margin: 0 0 4px 0;
      padding: 0; }

@media screen and (min-width: 769px) {
  .no-js nav a#nav-m, .no-js nav a.close, .no-js nav a:target ~ .close {
    display: none; }
  .no-js nav a:target ~ ul {
    background-color: transparent; }
  nav button.menu-toggle {
    display: none; }
  nav > ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 0;
    max-width: 40em; }
    nav > ul li a.menu-link {
      border-bottom: none;
      justify-content: flex-start; }
  nav ul.dropdown {
    position: absolute; }
    nav ul.dropdown li a {
      border-bottom: 1px solid gray; } }

@media screen and (min-width: 960px) {
  nav > ul {
    max-width: 45em; } }

/*  End Navigation  */
.header {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 140px; }
  .header figure {
    padding: 10px 10px 0; }
  .header img {
    display: none; }
  .header h1 {
    text-decoration: none; }

@media only screen and (min-width: 27.5em) {
  .header img {
    display: block;
    max-width: 26.5vw;
    align-self: flex-start;
    margin: 0.250em 0 0 3vw; } }

@media only screen and (min-width: 48.063em) {
  header#top {
    padding-bottom: 45px;
    margin-bottom: -39px; }
  .header {
    justify-content: space-around; }
    .header img {
      max-width: 12.8em;
      margin-top: 0.75em;
      margin-left: 0; } }

@media only screen and (min-width: 58.5em) {
  header#top {
    margin-bottom: -40px; } }

@media only screen and (min-width: 66.563em) {
  header#top {
    margin-bottom: -41px; } }

@media only screen and (min-width: 78.125em) {
  header#top {
    margin-bottom: -42px; } }

@media only screen and (min-width: 80em) {
  header#top {
    margin-bottom: -43px; } }

/*  Layout SASS File */
main {
  min-height: 50vh; }

.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1px; }
  .flex > div {
    max-width: 52em;
    min-width: 254px;
    padding: 0 1em; }

@media screen and (min-width: 48.063em) {
  .wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 74em; }
  .flex {
    flex-direction: row;
    flex-flow: wrap; }
    .flex > div.w33 {
      width: 33.333333333333%; }
    .flex > div.w40 {
      width: 40%; }
    .flex > div.w60 {
      text-align: left;
      width: 60%; }
    .flex > div.w66 {
      width: 66.666666666666%; }
    .flex > div.w100 {
      width: 100%; }
    .flex > div h2 {
      text-align: center; }
  .w60 h4:first-of-type {
    margin-top: 0; } }

.to-top-wrap {
  bottom: 0;
  pointer-events: none;
  position: absolute;
  right: 0.75rem;
  top: 120vh;
  width: 3em; }

.to-top {
  background-color: rgba(0, 155, 255, 0.3);
  background-image: url(../img/pfeilup.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  border-radius: 50%;
  border: 1px solid #007cff;
  display: inline-block;
  height: 3rem;
  opacity: 0.8;
  pointer-events: all;
  position: fixed;
  position: -webkit-sticky;
  position: sticky;
  top: calc(100vh - 5rem);
  transition: 0.5s;
  width: 3rem; }
  .to-top:focus, .to-top:hover {
    background-color: rgba(0, 155, 255, 0.6);
    border: 1px solid #0047c5;
    box-shadow: 0 0 0 2px #0047c5;
    outline: none;
    transform: scale(1.05); }

@media only screen and (min-width: 1150px) {
  .to-top-wrap {
    right: 1rem; } }

/*   Footer SASS */
footer {
  padding: 1em;
  text-align: center; }
  footer p {
    margin: 0.5em 0; }

footer li {
  list-style-type: none;
  margin: 0; }

footer a:focus, footer a:hover, footer a:active {
  background-color: rgba(0, 102, 255, 0.3);
  border-bottom: 1px solid red;
  color: #001090; }

footer a.active {
  background-color: transparent;
  border-bottom: none;
  color: #000;
  text-decoration: none; }
  footer a.active:hover {
    cursor: default; }

@media screen and (min-width: 48.063em) {
  footer p {
    margin: 0.75em 0; } }

#up {
  opacity: 0;
  -webkit-transition: .5s;
  transition: .5s;
  position: fixed;
  color: transparent; }
  #up a {
    background-image: url("../img/pfeilup1.svg");
    background-color: rgba(0, 155, 255, 0.3);
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
    border: 1px solid #007cff;
    bottom: 20px;
    opacity: .7;
    padding: 1.4rem;
    position: fixed;
    right: 15px;
    transition: .5s;
    z-index: 100; }
    #up a:focus, #up a:hover {
      background-color: rgba(0, 155, 255, 0.6);
      border: 1px solid #005cff;
      opacity: 1; }
