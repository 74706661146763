/*   Footer SASS */

footer {
  padding: 1em;
  text-align: center;
  p {
    margin: 0.5em 0; } }

footer {
  li {
    list-style-type: none;
    margin: 0; }

  a {
    &:focus, &:hover, &:active {
      background-color: rgba(0, 102, 255, 0.3);
      border-bottom: 1px solid red;
      color: #001090; }
    &.active {
      background-color: transparent;
      border-bottom: none;
      color: #000;
      text-decoration: none;
      &:hover {
        cursor: default; } } } }

@media screen and (min-width: 48.063em) {
  footer {
    p {
      margin: 0.75em 0; } } }

#up {
  opacity: 0;
  -webkit-transition: .5s;
  transition: .5s;
  position: fixed;
  color: transparent;
  a {
    background-image: url("../img/pfeilup1.svg");
    background-color: rgba(0, 155, 255, 0.3);
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
    border: 1px solid #007cff;
    bottom: 20px;
    opacity: .7;
    padding: 1.4rem;
    position: fixed;
    right: 15px;
    transition: .5s;
    z-index: 100;
    &:focus, &:hover {
      background-color: rgba(0, 155, 255, 0.6);
      border: 1px solid #005cff;
      opacity: 1; } } }
