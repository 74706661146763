hr {
  border: 0;
  border-top: 1px solid #00e5ff;
  display: block;
  margin: 2em auto;
  padding: 0;
  width: 90%; }

img {
  height: auto;
  max-width: 100%; }

.hidden, [hidden] {
  display: none !important; }

.vh {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &.focusable {
    &:active, &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      white-space: inherit;
      width: auto; } } }

.clearfix {
  &:after, &:before {
    content: " ";
    display: table; }

  &:after {
    clear: both; } }

.nobreak {
  hyphens: none;
  white-space: nowrap; }

.box {
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
  border-color: #007ffc;
  border-radius: .3em;
  border-style: solid;
  border-width: 1px;
  color: #111;
  margin: 1.5em 0;
  padding: 1.4vw;
  h3:first-of-type,
  h4:first-of-type {
    margin-top: 0.2em; } }


.info {
  background-color: #d0ffff;
  min-width: 200px;
  border: 1px solid blue;
  margin-left: 3px; }


.adress {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap; }

.success {
  background: #dcffcf; }

// .warning
//   background: #ffffb9

// .error
//   background: #ffb9b9

.float-left {
  display: block;
  float: left;
  margin: 0 1em 1.25em 0; }

.float-right {
  display: block;
  float: right;
  margin: 0 0 1.25em 1em; }

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0.66em 0;
  text-align: center; }

.text-links {
  text-align: left; }

.red {
  color: red; }

.bold {
  font-weight: 700; }

.underline {
  text-decoration: underline;
  text-underline-position: under; }

.linklist {
  margin: 0 0 16px 18px;
  &:before {
    content: " - ";
    margin-left: -13px; } }
