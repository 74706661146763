/*  Scss Modular Fluid Text Document  */
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($number) {
  @if type-of($number)=="number"and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

html {
  color: #222;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 100%;
  line-height: 1.5;
  scroll-behavior: smooth;
}

body {
  font-size: 1rem;
  overflow-wrap: normal;
}

// Fluid Modular Scale this!
h1,
h2,
h3,
h4 {
  letter-spacing: 0.0313em;
  margin: 0.75em 0;
  font-family: nunito;
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
  text-underline-position: under;
}

h1:focus,
h2:focus,
h3:focus,
h4:focus,
button:focus {
  outline: 2px solid red;
}

p,
figcaption {
  letter-spacing: 0.0313em;
  margin: 0.75em 0;
}

h1 {
  @include fluid-type(font-size, 30em, 80em, 1.731rem, 3.093rem);
}

h2 {
  @include fluid-type(font-size, 30em, 80em, 1.5rem, 2.468rem);
}

h3 {
  @include fluid-type(font-size, 30em, 80em, 1.25rem, 1.968rem);
}

h4 {
  @include fluid-type(font-size, 30em, 80em, 1.175rem, 1.718rem);
}

a,
p,
li,
figcaption,
button {
  @include fluid-type(font-size, 30em, 80em, 1.031rem, 1.25rem);
}

.small {
  font-size: small;
}

a {
  color: #1300e1;
  letter-spacing: 0.0313em;
  padding: 4px;
  text-decoration: underline;
  text-underline-position: under;
  white-space: normal;

  &:visited {
    color: #0070f1;
  }

  &:focus {
    background-color: rgba(0, 255, 255, 0.3);
    border-bottom: 1px solid red;
    color: #001090;
    outline-style: auto;
    outline: 2px solid red;
    text-decoration: none;
  }

  &:hover {
    background-color: rgba(0, 255, 255, 0.3);
    border-bottom: 1px solid red;
    color: #001090;
    cursor: pointer;
    text-decoration: none;
  }

  &:before,
  &:after {
    text-decoration: none;
  }
}

a[target="_blank"]:not([href*="tauchclub-octopus.ch"]):not([href*="pdf/"]) {
  background-image: url("../img/extern.svg");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 14px;
  padding: 2px 16px 0 0;
  text-decoration: none;
}

a[target="_blank"]:not([href*="tauchclub-octopus.ch"]):not([href^="pdf/"]):hover {
  background-color: #ffdcdc;
}

a[href*="mailto:"] {
  background-image: url("../img/mail.svg");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 25px;
}

li {
  list-style-type: disc;
  margin-left: 1.5rem;
}

// ICONS SVG
.icon {
  align-self: center;
  fill: currentColor;
  height: 1em;
  margin-right: 3px;
  position: relative;
  stroke-width: 0;
  stroke: currentColor;
  width: 1em;
}

.icon-file-pdf {
  color: red;
  margin-left: 2px;
}

// Single-colored icons can be modified like so:
// .icon-name {
//   font-size: 32px;
//   color: red;
//}

/* nunito-regular - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url("../fonts/nunito-v12-latin-regular.woff2") format("woff2"),
    url("../fonts/nunito-v12-latin-regular.woff") format("woff");
  font-display: swap;
}

/*    End Fluid scss    */