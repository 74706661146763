/* Navigation   */
.no-js nav a {
    &#nav-m, &.close {
        background: transparent;
        border-radius: .3em;
        border: 2px solid #333;
        color: #000;
        display: block;
        font-size: 1.5rem;
        padding: .1em .25em;
        position: absolute;
        right: 2px;
        text-decoration: none;
        top: 3.875em;
        z-index: 2; }

    &#nav-m:hover, &.close:hover {
        background-color: #f6eb00; }

    &#nav-m img, &.close img {
        margin-left: 0.250em;
        width: 22px; }

    &.close, &#nav-m:target {
        display: none; }

    &:target ~ ul {
        display: flex;
        background-color: #00f5ff; }
    &:target ~ .close {
        display: block; } }

nav {
    background-color: transparent;
    color: #000;
    a {
        &#nav-m, &.close {
            display: none; } } }

.no-js button.menu-toggle {
    display: none; }

button.menu-toggle {
    align-items: center;
    background-color: transparent;
    border-radius: 0.3em;
    border: 2px solid #333;
    color: #000;
    display: flex;
    font-size: 1.5rem;
    margin-left: auto;
    padding: 0.3em;
    position: absolute;
    right: 2px;
    top: 3.875em;
    &:hover {
        background-color: #f6eb00; }
    img {
        margin-left: 0.250em;
        width: 22px; }

    &[aria-expanded="true"] img.menu-img, &[aria-expanded="false"] img.close {
        display: none; }

    &[aria-expanded="true"] img.close {
        display: inline-block; } }

nav > ul {
    display: none;
    flex-direction: column;
    margin: 0 auto;

    &.show {
        display: flex;
        background-color: #00f5ff; }

    ul.dropdown {
        background-color: #00f5ff;
        flex-direction: column;
        max-height: 0;
        transition: .1s ease-in .8s;
        visibility: hidden;
        margin-top: 0; }

    li.dropdown {
        &:hover ul.dropdown, a:focus + ul.dropdown, ul.dropdown:focus-within {
            max-height: 400px;
            transition: .3s ease-out .3s;
            visibility: visible; } } }

nav ul {
    &.dropdown.show {
        display: flex; }

    li {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;

        &.dropdown > a::after {
            content: " ▼";
            display: inline-block; }

        a.menu-link {
            align-items: baseline;
            border-bottom: 1px solid gray;
            color: #000;
            display: flex;
            justify-content: center;
            margin: 0;
            padding: .4rem 1.4rem;
            text-decoration: underline;
            text-underline-position: under;

            &:hover, &:focus {
                background-color: #0a2d6d;
                color: #f5faff;
                text-decoration-color: currentColor; }

            &.active {
                background-color: #d3d3d3;
                color: currentColor;
                cursor: default;
                text-decoration: none;

                &:hover, &:focus {
                    background-color: #d3d3d3;
                    color: currentColor;
                    cursor: default;
                    text-decoration: none; } }

            & svg {
                align-self: center; }
            svg.icon-file-pdf {
                align-self: flex-end;
                margin: 0 0 4px 0;
                padding: 0; } } } }

@media screen and (min-width: 769px) {
    .no-js nav a {
        &#nav-m, &.close, &:target ~ .close {
            display: none; }
        &:target ~ ul {
            background-color: transparent; } }

    nav button.menu-toggle {
        display: none; }

    nav > ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 0;
        max-width: 40em;

        li a.menu-link {
            border-bottom: none;
            justify-content: flex-start; } }

    nav ul.dropdown {
        position: absolute;
        li a {
            border-bottom: 1px solid gray; } } }

@media screen and (min-width: 960px) {
    nav > ul {
        max-width: 45em; } }
/*  End Navigation  */
