
.header {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 140px;
  figure {
    padding: 10px 10px 0; }
  img {
    display: none; }
  h1 {
    text-decoration: none; } }

@media only screen and (min-width: 27.5em) {
  .header img {
    display: block;
    max-width: 26.5vw;
    align-self: flex-start;
    margin: 0.250em 0 0 3vw; } }

@media only screen and (min-width: 48.063em) {
  header#top {
    padding-bottom: 45px;
    margin-bottom: -39px; }

  .header {
    justify-content: space-around;
    img {
      max-width: 12.8em;
      margin-top: 0.75em;
      margin-left: 0; } } }

@media only screen and (min-width: 58.5em) {
  header#top {
    margin-bottom: -40px; } }

@media only screen and (min-width: 66.563em) {
  header#top {
    margin-bottom: -41px; } }

@media only screen and (min-width: 78.125em) {
  header#top {
    margin-bottom: -42px; } }

@media only screen and (min-width: 80em) {
  header#top {
    margin-bottom: -43px; } }
