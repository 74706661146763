/* Scss Document Mixins */

@mixin gradient($color-one, $color-two, $degrees) {
  background: -moz-linear-gradient(
    $degrees,
    $color-one 0%,
    $color-two 100%
  ); /* ff3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, $color-one),
    color-stop(100%, $color-two)
  ); /* safari4+,chrome */
  background: -webkit-linear-gradient(
    $degrees,
    $color-one 0%,
    $color-two 100%
  ); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(
    $degrees,
    $color-one 0%,
    $color-two 100%
  ); /* opera 11.10+ */
  background: -ms-linear-gradient(
    $degrees,
    $color-one 0%,
    $color-two 100%
  ); /* ie10+ */
  background: linear-gradient(
    $degrees,
    $color-one 0%,
    $color-two 100%
  ); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two',GradientType=1 ); /* ie6-9 */
}

/* mixin usage header */
.gradient {
  @include gradient(#00a9ff, #00f4ff, 180deg);
}
/* mixin usage nav  */
.gradient-nav {
  @include gradient(#00effb, #36e9ff, 180deg);
}

.gradient-footer {
  @include gradient(#00f3ff, #36e9ff, 180deg);
}
