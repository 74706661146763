/*  Layout SASS File */
main {
  min-height: 50vh; }

.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1px;

  > div {
    max-width: 52em;
    min-width: 254px;
    padding: 0 1em; } }

@media screen and (min-width: 48.063em) {
  .wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 74em; }

  .flex {
    flex-direction: row;
    flex-flow: wrap;
    > div {
      &.w33 {
        width: 33.333333333333%; }

      &.w40 {
        width: 40%; }

      &.w60 {
        text-align: left;
        width: 60%; }

      &.w66 {
        width: 66.666666666666%; }

      &.w100 {
        width: 100%; }

      h2 {
        text-align: center; } } }

  .w60 h4:first-of-type {
    margin-top: 0; } }
